<template>
  <div class="column">
    <div class="pos_rel">
      <div class="top_text">全国各地区指标平均值</div>
      <div ref="ChinaMap" id="chinaMap" class="topLine"></div>
      <div class="label_row">
        <div class="column">
          <div
            class="row_center"
            @click="clickMapTab(1)"
            @mouseover="Mouseover(1)"
            @mouseleave="Mouseleave()"
          >
            <div
              :class="
                currentTab == 1 || mouthTab == 1
                  ? 'width62 color1 text_select'
                  : 'alpha width62 text_normal'
              "
            >
              身高
            </div>
          </div>
          <div
            class="row_center mt6"
            @click="clickMapTab(2)"
            @mouseover="Mouseover(2)"
            @mouseleave="Mouseleave()"
          >
            <div
              :class="
                currentTab == 2 || mouthTab == 2
                  ? 'width62 color2 text_select'
                  : 'alpha width62 text_normal'
              "
            >
              体重
            </div>
          </div>
          <div
            class="row_center mt6"
            @click="clickMapTab(3)"
            @mouseover="Mouseover(3)"
            @mouseleave="Mouseleave()"
          >
            <div
              :class="
                currentTab == 3 || mouthTab == 3
                  ? 'width62 color3 text_select'
                  : 'alpha width62 text_normal'
              "
            >
              视力
            </div>
          </div>
          <div
            class="row_center mt6"
            @click="clickMapTab(4)"
            @mouseover="Mouseover(4)"
            @mouseleave="Mouseleave()"
          >
            <div
              :class="
                currentTab == 4 || mouthTab == 4
                  ? 'width62 color4 text_select'
                  : 'alpha width62 text_normal'
              "
            >
              臂展
            </div>
          </div>
          <div
            class="row_center mt6"
            @click="clickMapTab(5)"
            @mouseover="Mouseover(5)"
            @mouseleave="Mouseleave()"
          >
            <div
              :class="
                currentTab == 5 || mouthTab == 5
                  ? 'width62 color5 text_select'
                  : 'alpha width62 text_normal'
              "
            >
              肺活量
            </div>
          </div>
          <div
            class="row_center mt6"
            @click="clickMapTab(6)"
            @mouseover="Mouseover(6)"
            @mouseleave="Mouseleave()"
          >
            <div
              :class="
                currentTab == 6 || mouthTab == 6
                  ? 'width62 color6 text_select'
                  : 'alpha width62 text_normal'
              "
            >
              胸围
            </div>
          </div>
          <div
            class="row_center mt6"
            @click="clickMapTab(7)"
            @mouseover="Mouseover(7)"
            @mouseleave="Mouseleave()"
          >
            <div
              :class="
                currentTab == 7 || mouthTab == 7
                  ? 'width62 color7 text_select'
                  : 'alpha width62 text_normal'
              "
            >
              体前屈
            </div>
          </div>
          <div
            class="row_center"
            @click="clickMapTab(8)"
            @mouseover="Mouseover(8)"
            @mouseleave="Mouseleave()"
          >
            <div
              :class="
                currentTab == 8 || mouthTab == 8
                  ? 'width62 color8 text_select'
                  : 'alpha width62 text_normal'
              "
            >
              跑动
            </div>
          </div>
          <div
            class="row_center mt6"
            @click="clickMapTab(9)"
            @mouseover="Mouseover(9)"
            @mouseleave="Mouseleave()"
          >
            <div
              :class="
                currentTab == 9 || mouthTab == 9
                  ? 'width62 color9 text_select'
                  : 'alpha width62 text_normal'
              "
            >
              跳跃
            </div>
          </div>
          <div
            class="row_center mt6"
            @click="clickMapTab(10)"
            @mouseover="Mouseover(10)"
            @mouseleave="Mouseleave()"
          >
            <div
              :class="
                currentTab == 10 || mouthTab == 10
                  ? 'width62 color10 text_select'
                  : 'alpha width62 text_normal'
              "
            >
              敏捷
            </div>
          </div>
          <div
            class="row_center mt6"
            @click="clickMapTab(11)"
            @mouseover="Mouseover(11)"
            @mouseleave="Mouseleave()"
          >
            <div
              :class="
                currentTab == 11 || mouthTab == 11
                  ? 'width62 color11 text_select'
                  : 'alpha width62 text_normal'
              "
            >
              力量
            </div>
          </div>
          <div
            class="row_center mt6"
            @click="clickMapTab(12)"
            @mouseover="Mouseover(12)"
            @mouseleave="Mouseleave()"
          >
            <div
              :class="
                currentTab == 12 || mouthTab == 12
                  ? 'width62 color12 text_select'
                  : 'alpha width62 text_normal'
              "
            >
              协调
            </div>
          </div>
          <div
            class="row_center mt6"
            @click="clickMapTab(13)"
            @mouseover="Mouseover(13)"
            @mouseleave="Mouseleave()"
          >
            <div
              :class="
                currentTab == 13 || mouthTab == 13
                  ? 'width62 color13 text_select'
                  : 'alpha width62 text_normal'
              "
            >
              平衡
            </div>
          </div>
          <div
            class="row_center mt6"
            @click="clickMapTab(14)"
            @mouseover="Mouseover(14)"
            @mouseleave="Mouseleave()"
          >
            <div
              :class="
                currentTab == 14 || mouthTab == 14
                  ? 'width62 color14 text_select'
                  : 'alpha width62 text_normal'
              "
            >
              物控手
            </div>
          </div>
          <div
            class="row_center mt6"
            @click="clickMapTab(15)"
            @mouseover="Mouseover(15)"
            @mouseleave="Mouseleave()"
          >
            <div
              :class="
                currentTab == 15 || mouthTab == 15
                  ? 'width62 color15 text_select'
                  : 'alpha width62 text_normal'
              "
            >
              物控脚
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="title">幼儿全国对比</div>
    <img class="pes" src="../../assets/img/home/home/PES/pes.png" />
    <div class="content mt15">
      通过与全国平均值对比，观察您幼儿园本月体质和运动数据高低，低于全国平均值水平的指标需要重点关注
    </div>
    <div class="content">引入英国原创视频课程</div>
    <div class="baseLine mt72">
      <div class="width514">
        <div class="top_lay">
          <div class="top_bg pos_abs">
            <div class="middle">上月体质平均值</div>
          </div>
        </div>
        <div class="hint1">上月体质测试全国平均值对比园所平均值</div>
        <div class="num_lay mt36">
          <div class="left_lay">
            <span class="text">{{
              rateData.B_height ? rateData.B_height[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">身高</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.B_height && rateData.B_height[1]"
              class="text"
              >{{ rateData.B_height[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.B_weight ? rateData.B_weight[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">体重</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.B_weight && rateData.B_weight[1]"
              class="text"
              >{{ rateData.B_weight[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.B_eye_L ? rateData.B_eye_L[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">视力</div>
          </div>
          <div class="left_lay center">
            <span v-if="rateData.B_eye_L && rateData.B_eye_L[1]" class="text">{{
              rateData.B_eye_L[1]
            }}</span>
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.B_arm ? rateData.B_arm[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">臂展</div>
          </div>
          <div class="left_lay center">
            <span v-if="rateData.B_arm && rateData.B_arm[1]" class="text">{{
              rateData.B_arm[1]
            }}</span>
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.B_lung ? rateData.B_lung[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">肺活量</div>
          </div>
          <div class="left_lay center">
            <span v-if="rateData.B_lung && rateData.B_lung[1]" class="text">{{
              rateData.B_lung[1]
            }}</span>
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.B_bust ? rateData.B_bust[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">胸围</div>
          </div>
          <div class="left_lay center">
            <span v-if="rateData.B_bust && rateData.B_bust[1]" class="text">{{
              rateData.B_bust[1]
            }}</span>
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.B_flexion ? rateData.B_flexion[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">体前屈</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.B_flexion && rateData.B_flexion[1]"
              class="text"
              >{{ rateData.B_flexion[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
      </div>
      <div class="width514 ml55">
        <div class="top_lay">
          <div class="top_bg pos_abs">
            <div class="middle">上月运动平均值</div>
          </div>
        </div>
        <div class="hint1">上月运动测试全国平均值对比园所平均值</div>
        <div class="num_lay mt36">
          <div class="left_lay">
            <span class="text">{{
              rateData.S_run ? rateData.S_run[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">跑动</div>
          </div>
          <div class="left_lay center">
            <span v-if="rateData.S_run && rateData.S_run[1]" class="text">{{
              rateData.S_run[1]
            }}</span>
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.S_jump ? rateData.S_jump[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">跳跃</div>
          </div>
          <div class="left_lay center">
            <span v-if="rateData.S_jump && rateData.S_jump[1]" class="text">{{
              rateData.S_jump[1]
            }}</span>
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.S_agile ? rateData.S_agile[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">敏捷</div>
          </div>
          <div class="left_lay center">
            <span v-if="rateData.S_agile && rateData.S_agile[1]" class="text">{{
              rateData.S_agile[1]
            }}</span>
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.S_power ? rateData.S_power[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">力量</div>
          </div>
          <div class="left_lay center">
            <span v-if="rateData.S_power && rateData.S_power[1]" class="text">{{
              rateData.S_power[1]
            }}</span>
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.S_coordinate ? rateData.S_coordinate[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">协调</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.S_coordinate && rateData.S_coordinate[1]"
              class="text"
              >{{ rateData.S_coordinate[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.S_balance ? rateData.S_balance[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">平衡</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.S_balance && rateData.S_balance[1]"
              class="text"
              >{{ rateData.S_balance[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.S_ctrl_hand ? rateData.S_ctrl_hand[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">物控手</div>
          </div>
          <div v-if="rateData.S_ctrl_hand" class="left_lay center">
            <span v-if="rateData.S_ctrl_hand[1]" class="text">{{
              rateData.S_ctrl_hand[1]
            }}</span>
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
        <div class="num_lay mt16">
          <div class="left_lay">
            <span class="text">{{
              rateData.S_ctrl_foot ? rateData.S_ctrl_foot[0] : "-"
            }}</span>
          </div>
          <div class="column_num center flex">
            <div class="row">
              <div class="text">全国</div>
              <img
                class="vs"
                src="../../assets/img/home/home/PES/Icon_Vs.png"
              />
              <div class="text">本园所</div>
            </div>
            <div class="line"></div>
            <div class="value">物控脚</div>
          </div>
          <div class="left_lay center">
            <span
              v-if="rateData.S_ctrl_foot && rateData.S_ctrl_foot[1]"
              class="text"
              >{{ rateData.S_ctrl_foot[1] }}</span
            >
            <span v-else class="empty_text">未测试</span>
          </div>
        </div>
      </div>
    </div>
    <div class="analyse center" @click="clickAnalyse">一键分析</div>
    <div id="analyseChart" class="analyse_chart"></div>
    <div class="row width1400">
      <div class="flex column_center">
        <div class="analyse_tab">
          <span class="text2">{{
            times[0] ? times[0].replace("-", ".") : ""
          }}</span>
        </div>
        <div v-if="!analyseData[0]" class="red_text">未测试</div>
      </div>
      <div class="flex column_center">
        <div class="analyse_tab">
          <span class="text2">{{
            times[1] ? times[1].replace("-", ".") : ""
          }}</span>
        </div>
        <div v-if="!analyseData[1]" class="red_text">未测试</div>
      </div>
      <div class="flex column_center">
        <div class="analyse_tab">
          <span class="text2">{{
            times[2] ? times[2].replace("-", ".") : ""
          }}</span>
        </div>
        <div v-if="!analyseData[2]" class="red_text">未测试</div>
      </div>
      <div class="flex column_center">
        <div class="analyse_tab">
          <span class="text2">{{
            times[3] ? times[3].replace("-", ".") : ""
          }}</span>
        </div>
        <div v-if="!analyseData[3]" class="red_text">未测试</div>
      </div>
      <div class="flex column_center">
        <div class="analyse_tab">
          <span class="text2">{{
            times[4] ? times[4].replace("-", ".") : ""
          }}</span>
        </div>
        <div v-if="!analyseData[4]" class="red_text">未测试</div>
      </div>
      <div class="flex column_center">
        <div class="analyse_tab">
          <span class="text2">{{
            times[5] ? times[5].replace("-", ".") : ""
          }}</span>
        </div>
        <div v-if="!analyseData[5]" class="red_text">未测试</div>
      </div>
    </div>
    <div class="row mt26">
      <div class="row">
        <div
          class="label ml32"
          @click="clickAnalyseType(1)"
          @mouseover="Mouseover1(1)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 1 || mouthAnalyseType == 1
                ? 'width62 ys_color1 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[0] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseType(2)"
          @mouseover="Mouseover1(2)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 2 || mouthAnalyseType == 2
                ? 'width62 ys_color2 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[1] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseType(3)"
          @mouseover="Mouseover1(3)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 3 || mouthAnalyseType == 3
                ? 'width62 ys_color3 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[2] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseType(4)"
          @mouseover="Mouseover1(4)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 4 || mouthAnalyseType == 4
                ? 'width62 ys_color4 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[3] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseType(5)"
          @mouseover="Mouseover1(5)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 5 || mouthAnalyseType == 5
                ? 'width62 ys_color5 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[4] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseType(6)"
          @mouseover="Mouseover1(6)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 6 || mouthAnalyseType == 6
                ? 'width62 ys_color6 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[5] }}
          </div>
        </div>
        <div
          class="label ml20"
          @click="clickAnalyseType(7)"
          @mouseover="Mouseover1(7)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 7 || mouthAnalyseType == 7
                ? 'width62 ys_color7 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[6] }}
          </div>
        </div>

        <div
          class="label ml32"
          @click="clickAnalyseType(8)"
          @mouseover="Mouseover1(8)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 8 || mouthAnalyseType == 8
                ? 'width62 ys_color8 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[7] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseType(9)"
          @mouseover="Mouseover1(9)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 9 || mouthAnalyseType == 9
                ? 'width62 ys_color9 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[8] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseType(10)"
          @mouseover="Mouseover1(10)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 10 || mouthAnalyseType == 10
                ? 'width62 ys_color10 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[9] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseType(11)"
          @mouseover="Mouseover1(11)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 11 || mouthAnalyseType == 11
                ? 'width62 ys_color11 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[10] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseType(12)"
          @mouseover="Mouseover1(12)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 12 || mouthAnalyseType == 12
                ? 'width62 ys_color12 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[11] }}
          </div>
        </div>
        <div
          class="label ml32"
          @click="clickAnalyseType(13)"
          @mouseover="Mouseover1(13)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 13 || mouthAnalyseType == 13
                ? 'width62 ys_color13 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[12] }}
          </div>
        </div>
        <div
          class="label ml20"
          @click="clickAnalyseType(14)"
          @mouseover="Mouseover1(14)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 14 || mouthAnalyseType == 14
                ? 'width62 ys_color14 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[13] }}
          </div>
        </div>
        <div
          class="label ml20"
          @click="clickAnalyseType(15)"
          @mouseover="Mouseover1(15)"
          @mouseleave="Mouseleave1()"
        >
          <div
            :class="
              currentAnalyseType == 15 || mouthAnalyseType == 15
                ? 'width62 ys_color15 text_select'
                : 'alpha width62 text_normal'
            "
          >
            {{ names1[14] }}
          </div>
        </div>
      </div>
    </div>

    <div class="ys_name mt82">{{ ysName }}</div>
    <div class="result">分析结果：{{ message ? message : "暂无" }}</div>
    <div class="h240"></div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import china from "../../utils/china";
var geoCoordMap = {
  北京: ["116.3979471", "39.9081726"],
  上海: ["121.4692688", "31.2381763"],
  天津: ["117.2523808", "39.1038561"],
  重庆: ["106.548425", "29.5549144"],
  河北: ["114.4897766", "38.0451279"],
  山西: ["112.5223053", "37.8357424"],
  辽宁: ["123.4116821", "41.7966156"],
  吉林: ["125.3154297", "43.8925629"],
  黑龙江: ["126.6433411", "45.7414932"],
  浙江: ["120.1592484", "30.265995"],
  福建: ["119.2978134", "26.0785904"],
  山东: ["117.0056", "36.6670723"],
  河南: ["113.6500473", "34.7570343"],
  湖北: ["114.2919388", "30.5675144"],
  湖南: ["112.9812698", "28.2008247"],
  广东: ["113.2614288", "23.1189117"],
  海南: ["110.3465118", "20.0317936"],
  四川: ["104.0817566", "30.6610565"],
  贵州: ["106.7113724", "26.5768738"],
  云南: ["102.704567", "25.0438442"],
  江西: ["115.8999176", "28.6759911"],
  陕西: ["108.949028", "34.2616844"],
  青海: ["101.7874527", "36.6094475"],
  甘肃: ["103.7500534", "36.0680389"],
  广西: ["108.3117676", "22.8065434"],
  新疆: ["87.6061172", "43.7909393"],
  内蒙古: ["111.6632996", "40.8209419"],
  西藏: ["91.1320496", "29.657589"],
  宁夏: ["106.2719421", "38.4680099"],
  台湾: ["120.9581316", "23.8516062"],
  香港: ["114.139452", "22.391577"],
  澳门: ["113.5678411", "22.167654"],
  安徽: ["117.2757034", "31.8632545"],
  江苏: ["118.7727814", "32.0476151"],
};
var convertData = function (data) {
  var res = [];
  for (var i = 0; i < data.length; i++) {
    var geoCoord = geoCoordMap[data[i].name];
    if (geoCoord) {
      res.push({
        name: data[i].name,
        value: geoCoord.concat(data[i].value),
      });
    }
  }
  return res;
};
export default {
  name: "allCountrydb",
  components: {},
  data() {
    return {
      charts: null,
      title: "身高",
      mapAreaData: [],
      valueList: [],
      mapData: [],
      circleColor: "#00b29d",
      leftColor: "#00b29d",
      rightColor: "rgba(192, 40, 27, 1)",
      rateData: {},
      analyseData: [],
      qgHeight: [],
      ysHeight: [],
      times: [],
      ysName: "",
      message: "",
      currentTab: 1,
      mouthTab: -1,
      currentAnalyseType: 1,
      mouthAnalyseType: -1,
      names1: [
        "身高",
        "体重",
        "视力",
        "臂展",
        "肺活量",
        "胸围",
        "体前屈",
        "跑动",
        "跳跃",
        "敏捷",
        "力量",
        "协调",
        "平衡",
        "物控手",
        "物控脚",
      ],
      analyseValue: {},
      qgData: [],
    };
  },

  methods: {
    clickMapTab(index) {
      this.currentTab = index;
      if (index == 1) {
        this.title = "身高";
        this.circleColor = "#00b29d";
        this.valueList = [];
        for (let i = 0; i < this.mapData.length; i++) {
          this.valueList.push({
            id: i,
            num1: this.mapData[i].small.B_height,
            num2: this.mapData[i].middle.B_height,
            num3: this.mapData[i].big.B_height,
          });
        }
      } else if (index == 2) {
        this.title = "体重";
        this.circleColor = "#88391e";
        this.valueList = [];
        for (let i = 0; i < this.mapData.length; i++) {
          this.valueList.push({
            id: i,
            num1: this.mapData[i].small.B_weight,
            num2: this.mapData[i].middle.B_weight,
            num3: this.mapData[i].big.B_weight,
          });
        }
      } else if (index == 3) {
        this.title = "视力";
        this.circleColor = "#344f00";
        this.valueList = [];
        for (let i = 0; i < this.mapData.length; i++) {
          this.valueList.push({
            id: i,
            num1: this.mapData[i].small.B_eye_L,
            num2: this.mapData[i].middle.B_eye_L,
            num3: this.mapData[i].big.B_eye_L,
          });
        }
      } else if (index == 4) {
        this.title = "臂展";
        this.circleColor = "#002055";
        this.valueList = [];
        for (let i = 0; i < this.mapData.length; i++) {
          this.valueList.push({
            id: i,
            num1: this.mapData[i].small.B_arm,
            num2: this.mapData[i].middle.B_arm,
            num3: this.mapData[i].big.B_arm,
          });
        }
      } else if (index == 5) {
        this.title = "肺活量";
        this.circleColor = "#005047";
        this.valueList = [];
        for (let i = 0; i < this.mapData.length; i++) {
          this.valueList.push({
            id: i,
            num1: this.mapData[i].small.B_lung,
            num2: this.mapData[i].middle.B_lung,
            num3: this.mapData[i].big.B_lung,
          });
        }
      } else if (index == 6) {
        this.title = "胸围";
        this.circleColor = "#500009";
        this.valueList = [];
        for (let i = 0; i < this.mapData.length; i++) {
          this.valueList.push({
            id: i,
            num1: this.mapData[i].small.B_bust,
            num2: this.mapData[i].middle.B_bust,
            num3: this.mapData[i].big.B_bust,
          });
        }
      } else if (index == 7) {
        this.title = "体前屈";
        this.circleColor = "#41cbb9";
        this.valueList = [];
        for (let i = 0; i < this.mapData.length; i++) {
          this.valueList.push({
            id: i,
            num1: this.mapData[i].small.B_flexion,
            num2: this.mapData[i].middle.B_flexion,
            num3: this.mapData[i].big.B_flexion,
          });
        }
      } else if (index == 8) {
        this.title = "跑动";
        this.circleColor = "#a37d8c";
        this.valueList = [];
        for (let i = 0; i < this.mapData.length; i++) {
          this.valueList.push({
            id: i,
            num1: this.mapData[i].small.S_run,
            num2: this.mapData[i].middle.S_run,
            num3: this.mapData[i].big.S_run,
          });
        }
      } else if (index == 9) {
        this.title = "跳跃";
        this.circleColor = "#55242a";
        this.valueList = [];
        for (let i = 0; i < this.mapData.length; i++) {
          this.valueList.push({
            id: i,
            num1: this.mapData[i].small.S_jump,
            num2: this.mapData[i].middle.S_jump,
            num3: this.mapData[i].big.S_jump,
          });
        }
      } else if (index == 10) {
        this.title = "敏捷";
        this.circleColor = "#2e7aa2";
        this.valueList = [];
        for (let i = 0; i < this.mapData.length; i++) {
          this.valueList.push({
            id: i,
            num1: this.mapData[i].small.S_agile,
            num2: this.mapData[i].middle.S_agile,
            num3: this.mapData[i].big.S_agile,
          });
        }
      } else if (index == 11) {
        this.title = "力量";
        this.circleColor = "#263d69";
        this.valueList = [];
        for (let i = 0; i < this.mapData.length; i++) {
          this.valueList.push({
            id: i,
            num1: this.mapData[i].small.S_power,
            num2: this.mapData[i].middle.S_power,
            num3: this.mapData[i].big.S_power,
          });
        }
      } else if (index == 12) {
        this.title = "协调";
        this.circleColor = "#705d17";
        this.valueList = [];
        for (let i = 0; i < this.mapData.length; i++) {
          this.valueList.push({
            id: i,
            num1: this.mapData[i].small.S_coordinate,
            num2: this.mapData[i].middle.S_coordinate,
            num3: this.mapData[i].big.S_coordinate,
          });
        }
      } else if (index == 13) {
        this.title = "平衡";
        this.circleColor = "#230db5";
        this.valueList = [];
        for (let i = 0; i < this.mapData.length; i++) {
          this.valueList.push({
            id: i,
            num1: this.mapData[i].small.S_balance,
            num2: this.mapData[i].middle.S_balance,
            num3: this.mapData[i].big.S_balance,
          });
        }
      } else if (index == 14) {
        this.title = "物控手";
        this.circleColor = "#14c0de";
        this.valueList = [];
        for (let i = 0; i < this.mapData.length; i++) {
          this.valueList.push({
            id: i,
            num1: this.mapData[i].small.S_ctrl_hand,
            num2: this.mapData[i].middle.S_ctrl_hand,
            num3: this.mapData[i].big.S_ctrl_hand,
          });
        }
      } else if (index == 15) {
        this.title = "物控脚";
        this.circleColor = "#159c56";
        this.valueList = [];
        for (let i = 0; i < this.mapData.length; i++) {
          this.valueList.push({
            id: i,
            num1: this.mapData[i].small.S_ctrl_foot,
            num2: this.mapData[i].middle.S_ctrl_foot,
            num3: this.mapData[i].big.S_ctrl_foot,
          });
        }
      }
      this.drawMap();
    },
    Mouseover(index) {
      this.mouthTab = index;
    },
    Mouseleave() {
      this.mouthTab = -1;
    },
    // 一键分析
    clickAnalyse() {
      this.currentAnalyseType = 1;
      this.leftColor = "#00b29d";
      this.rightColor = "rgba(192, 40, 27, 1)";
      this.qgData = [];
      this.analyseData = [];
      this.qgHeight = [];
      this.ysHeight = [];
      for (let i = 0; i < this.analyseValue.length; i++) {
        this.qgData.push(this.analyseValue[i].B_height[0]);
        this.analyseData.push(this.analyseValue[i].B_height[1]);
        this.qgHeight.push(this.analyseValue[i].B_height_kd[0]);
        this.ysHeight.push(this.analyseValue[i].B_height_kd[1]);
      }
      this.drawAnalyseLine("analyseChart");
    },
    clickAnalyseType(index) {
      this.currentAnalyseType = index;
      this.qgData = [];
      this.analyseData = [];
      this.qgHeight = [];
      this.ysHeight = [];
      if (index == 1) {
        this.leftColor = "#00b29d";
        this.rightColor = "rgba(192, 40, 27, 1)";
        for (let i = 0; i < this.analyseValue.length; i++) {
          this.qgData.push(this.analyseValue[i].B_height[0]);
          this.analyseData.push(this.analyseValue[i].B_height[1]);
          this.qgHeight.push(this.analyseValue[i].B_height_kd[0]);
          this.ysHeight.push(this.analyseValue[i].B_height_kd[1]);
        }
      } else if (index == 2) {
        this.leftColor = "#88391e";
        this.rightColor = "rgba(64, 209, 0, 1)";
        for (let i = 0; i < this.analyseValue.length; i++) {
          this.qgData.push(this.analyseValue[i].B_weight[0]);
          this.analyseData.push(this.analyseValue[i].B_weight[1]);
          this.qgHeight.push(this.analyseValue[i].B_weight_kd[0]);
          this.ysHeight.push(this.analyseValue[i].B_weight_kd[1]);
        }
      } else if (index == 3) {
        this.leftColor = "#344f00";
        this.rightColor = "rgba(0, 168, 193, 1)";
        for (let i = 0; i < this.analyseValue.length; i++) {
          this.qgData.push(this.analyseValue[i].B_eye_L[0]);
          this.analyseData.push(this.analyseValue[i].B_eye_L[1]);
          this.qgHeight.push(this.analyseValue[i].B_eye_L_kd[0]);
          this.ysHeight.push(this.analyseValue[i].B_eye_L_kd[1]);
        }
      } else if (index == 4) {
        this.leftColor = "#002055";
        this.rightColor = "rgba(224, 200, 0, 1)";
        for (let i = 0; i < this.analyseValue.length; i++) {
          this.qgData.push(this.analyseValue[i].B_arm[0]);
          this.analyseData.push(this.analyseValue[i].B_arm[1]);
          this.qgHeight.push(this.analyseValue[i].B_arm_kd[0]);
          this.ysHeight.push(this.analyseValue[i].B_arm_kd[1]);
        }
      } else if (index == 5) {
        this.leftColor = "#005047";
        this.rightColor = "rgba(244, 138, 0, 1)";
        for (let i = 0; i < this.analyseValue.length; i++) {
          this.qgData.push(this.analyseValue[i].B_lung[0]);
          this.analyseData.push(this.analyseValue[i].B_lung[1]);
          this.qgHeight.push(this.analyseValue[i].B_lung_kd[0]);
          this.ysHeight.push(this.analyseValue[i].B_lung_kd[1]);
        }
      } else if (index == 6) {
        this.leftColor = "#500009";
        this.rightColor = "rgba(39, 0, 237, 1)";
        for (let i = 0; i < this.analyseValue.length; i++) {
          this.qgData.push(this.analyseValue[i].B_bust[0]);
          this.analyseData.push(this.analyseValue[i].B_bust[1]);
          this.qgHeight.push(this.analyseValue[i].B_bust_kd[0]);
          this.ysHeight.push(this.analyseValue[i].B_bust_kd[1]);
        }
      } else if (index == 7) {
        this.leftColor = "#41cbb9";
        this.rightColor = "rgba(214, 0, 253, 1)";
        for (let i = 0; i < this.analyseValue.length; i++) {
          this.qgData.push(this.analyseValue[i].B_flexion[0]);
          this.analyseData.push(this.analyseValue[i].B_flexion[1]);
          this.qgHeight.push(this.analyseValue[i].B_flexion_kd[0]);
          this.ysHeight.push(this.analyseValue[i].B_flexion_kd[1]);
        }
      } else if (index == 8) {
        this.leftColor = "#a37d8c";
        this.rightColor = "rgba(151, 32, 79, 1)";
        for (let i = 0; i < this.analyseValue.length; i++) {
          this.qgData.push(this.analyseValue[i].S_run[0]);
          this.analyseData.push(this.analyseValue[i].S_run[1]);
          this.qgHeight.push(this.analyseValue[i].S_run_kd[0]);
          this.ysHeight.push(this.analyseValue[i].S_run_kd[1]);
        }
      } else if (index == 9) {
        this.leftColor = "#55242a";
        this.rightColor = "rgba(150, 36, 49, 1)";
        for (let i = 0; i < this.analyseValue.length; i++) {
          this.qgData.push(this.analyseValue[i].S_jump[0]);
          this.analyseData.push(this.analyseValue[i].S_jump[1]);
          this.qgHeight.push(this.analyseValue[i].S_jump_kd[0]);
          this.ysHeight.push(this.analyseValue[i].S_jump_kd[1]);
        }
      } else if (index == 10) {
        this.leftColor = "#2e7aa2";
        this.rightColor = "#2ea27a";
        for (let i = 0; i < this.analyseValue.length; i++) {
          this.qgData.push(this.analyseValue[i].S_agile[0]);
          this.analyseData.push(this.analyseValue[i].S_agile[1]);
          this.qgHeight.push(this.analyseValue[i].S_agile_kd[0]);
          this.ysHeight.push(this.analyseValue[i].S_agile_kd[1]);
        }
      } else if (index == 11) {
        this.leftColor = "#263d69";
        this.rightColor = "#1f4487";
        for (let i = 0; i < this.analyseValue.length; i++) {
          this.qgData.push(this.analyseValue[i].S_power[0]);
          this.analyseData.push(this.analyseValue[i].S_power[1]);
          this.qgHeight.push(this.analyseValue[i].S_power_kd[0]);
          this.ysHeight.push(this.analyseValue[i].S_power_kd[1]);
        }
      } else if (index == 12) {
        this.leftColor = "#705d17";
        this.rightColor = "rgba(182, 150, 35, 1)";
        for (let i = 0; i < this.analyseValue.length; i++) {
          this.qgData.push(this.analyseValue[i].S_coordinate[0]);
          this.analyseData.push(this.analyseValue[i].S_coordinate[1]);
          this.qgHeight.push(this.analyseValue[i].S_coordinate_kd[0]);
          this.ysHeight.push(this.analyseValue[i].S_coordinate_kd[1]);
        }
      } else if (index == 13) {
        this.leftColor = "#230db5";
        this.rightColor = "rgba(10, 0, 81, 1)";
        for (let i = 0; i < this.analyseValue.length; i++) {
          this.qgData.push(this.analyseValue[i].S_balance[0]);
          this.analyseData.push(this.analyseValue[i].S_balance[1]);
          this.qgHeight.push(this.analyseValue[i].S_balance_kd[0]);
          this.ysHeight.push(this.analyseValue[i].S_balance_kd[1]);
        }
      } else if (index == 14) {
        this.leftColor = "#14c0de";
        this.rightColor = "rgba(24, 130, 148, 1)";
        for (let i = 0; i < this.analyseValue.length; i++) {
          this.qgData.push(this.analyseValue[i].S_ctrl_hand[0]);
          this.analyseData.push(this.analyseValue[i].S_ctrl_hand[1]);
          this.qgHeight.push(this.analyseValue[i].S_ctrl_hand_kd[0]);
          this.ysHeight.push(this.analyseValue[i].S_ctrl_hand_kd[1]);
        }
      } else if (index == 15) {
        this.leftColor = "#159c56";
        this.rightColor = "rgba(15, 193, 100, 1)";
        for (let i = 0; i < this.analyseValue.length; i++) {
          this.qgData.push(this.analyseValue[i].S_ctrl_foot[0]);
          this.analyseData.push(this.analyseValue[i].S_ctrl_foot[1]);
          this.qgHeight.push(this.analyseValue[i].S_ctrl_foot_kd[0]);
          this.ysHeight.push(this.analyseValue[i].S_ctrl_foot_kd[1]);
        }
      }
      this.drawAnalyseLine("analyseChart");
    },
    Mouseover1(index) {
      this.mouthAnalyseType = index;
    },
    Mouseleave1() {
      this.mouthAnalyseType = -1;
    },
    drawMap() {
      //初始化echarts实例 通过传参获取要放置的id名称
      var that = this;
      if (this.charts == null) {
        this.charts = echarts.init(document.getElementById("chinaMap"));
      }
      // 指定图表的配置项和数据
      var optionMap = {
        //设置 背景颜色
        backgroundColor: "#e8e8e8",
        tooltip: {
          trigger: "item",
          formatter: function (datas) {
            var res = datas.name + "-" + that.title + "<br/>";
            for (var i = 0; i < that.valueList.length; i++) {
              if (that.valueList[i].id == datas.value) {
                res += "小班：" + that.valueList[i].num1 + "<br/>";
                res += "中班：" + that.valueList[i].num2 + "<br/>";
                res += "大班：" + that.valueList[i].num3 + "<br/>";
                break;
              }
            }
            return res;
          },
        },
        geo: {
          map: "china",
          label: {
            emphasis: {
              show: false,
            },
          },
          roam: false,
          regions: this.mapAreaData,
        },
        //配置属性
        series: [
          {
            title: this.title,
            type: "map",
            map: "china",
            geoIndex: 0,
            roam: false,
            animation: false,
            data: this.mapAreaData,
          },
        ],
      };
      //使用制定的配置项和数据显示图表
      this.charts.setOption(optionMap);
    },
    // 分析折线图
    drawAnalyseLine(id) {
      if (this.analyseCharts == null) {
        this.analyseCharts = echarts.init(document.getElementById(id));
      }
      var that = this;
      this.analyseCharts.setOption({
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.7)", // 提示背景颜色，默认为透明度为0.7的黑色
          borderColor: "#333", // 提示边框颜色
          borderRadius: 4, // 提示边框圆角，单位px，默认为4
          borderWidth: 0, // 提示边框线宽，单位px，默认为0（无边框）
          formatter: function (datas) {
            console.log(datas);
            var res = datas[0].axisValue + "月<br/>";
            for (var i = 0; i < datas.length; i++) {
              var val = datas[i].value;
              res += datas[i].seriesName + "：" + val + "<br/>";
            }
            return "";
          },
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
            lineStyle: {
              // 直线指示器样式设置
              color: "#48b",
              width: 2,
              type: "solid",
            },
            shadowStyle: {
              // 阴影指示器样式设置
              width: "auto", // 阴影大小
              color: "rgba(0, 255, 198, 0.2)", // 阴影颜色
            },
          },
          textStyle: {
            color: "#fff",
          },
        },
        legend: {
          orient: "vertical",
          x: "left", //可设定图例在左、右、居中
          y: "top", //可设定图例在上、下、居中
          padding: [0, 0, 0, 0],
          data: ["全国", "园所"],
        },
        grid: {
          top: "50px",
          left: "0px",
          right: "0px",
          bottom: "10px",
        },
        xAxis: {
          type: "category",
          data: this.times,
          axisTick: {
            show: false, //去除刻度线
          },
          axisLine: {
            show: true, //去除轴线
          },
          axisLabel: {
            color: "#e8e8e8", //x轴文本颜色
            inside: true,
            formatter: function () {
              return "";
            },
          },
          splitLine: {
            // 分隔线
            show: false, // 默认显示，属性show控制显示与否
            lineStyle: {
              // 属性lineStyle（详见lineStyle）控制线条样式
              color: "#C2C2C2",
              width: 2,
              type: "solid",
            },
          },
        },
        yAxis: [
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: false, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            max: 10,
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
          },
        ],

        series: [
          {
            name: "全国",
            type: "bar",
            data: this.qgHeight,
            smooth: true,
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: this.leftColor,
                lineStyle: {
                  width: 6, //设置线条粗细
                },
                label: {
                  show: true,
                  position: "top",
                  formatter: function (data) {
                    console.log(data);
                    var res = that.qgData[data.dataIndex];
                    if (that.currentAnalyseType == 1) {
                      res += "cm";
                    } else if (that.currentAnalyseType == 2) {
                      res += "kg";
                    } else if (that.currentAnalyseType == 4) {
                      res += "cm";
                    } else if (that.currentAnalyseType == 5) {
                      res += "ml";
                    } else if (that.currentAnalyseType == 6) {
                      res += "cm";
                    } else if (that.currentAnalyseType == 7) {
                      res += "cm";
                    } else if (that.currentAnalyseType == 8) {
                      res += "s";
                    } else if (that.currentAnalyseType == 9) {
                      res += "s";
                    } else if (that.currentAnalyseType == 10) {
                      res += "s";
                    } else if (that.currentAnalyseType == 11) {
                      res += "s";
                    } else if (that.currentAnalyseType == 12) {
                      res += "s";
                    } else if (that.currentAnalyseType == 13) {
                      res += "s";
                    } else if (that.currentAnalyseType == 14) {
                      res += "个";
                    } else if (that.currentAnalyseType == 15) {
                      res += "个";
                    }
                    return res;
                  },
                },
              },
            },
          },
          {
            name: "园所",
            type: "bar",
            data: this.ysHeight,
            smooth: true,
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: this.rightColor,

                lineStyle: {
                  width: 6, //设置线条粗细
                },
                label: {
                  show: true,
                  position: "top",
                  formatter: function (data) {
                    var res = that.analyseData[data.dataIndex];
                    if (!that.analyseData[data.dataIndex]) {
                      res = "0.0";
                    }
                    if (that.currentAnalyseType == 1) {
                      res += "cm";
                    } else if (that.currentAnalyseType == 2) {
                      res += "kg";
                    } else if (that.currentAnalyseType == 4) {
                      res += "cm";
                    } else if (that.currentAnalyseType == 5) {
                      res += "ml";
                    } else if (that.currentAnalyseType == 6) {
                      res += "cm";
                    } else if (that.currentAnalyseType == 7) {
                      res += "cm";
                    } else if (that.currentAnalyseType == 8) {
                      res += "s";
                    } else if (that.currentAnalyseType == 9) {
                      res += "s";
                    } else if (that.currentAnalyseType == 10) {
                      res += "s";
                    } else if (that.currentAnalyseType == 11) {
                      res += "s";
                    } else if (that.currentAnalyseType == 12) {
                      res += "s";
                    } else if (that.currentAnalyseType == 13) {
                      res += "s";
                    } else if (that.currentAnalyseType == 14) {
                      res += "个";
                    } else if (that.currentAnalyseType == 15) {
                      res += "个";
                    }
                    return res;
                  },
                },
              },
            },
          },
        ],
      });
    },
    // 获取地图数据
    getMapData() {
      let data = {};
      this.api.pes.childrenDbMapData(data).then((res) => {
        this.mapData = res.data;
        for (let i = 0; i < this.mapData.length; i++) {
          var id = i;
          if (!this.mapData[i].small.B_height) {
            id = -1;
          }
          var item = {
            name: this.mapData[i].province,
            value: id,
            itemStyle: {
              normal: { areaColor: "#B42C3B", label: { show: false } },
              borderColor: "skyblue",
              borderWidth: 1,
            },
          };
          if (
            item.name == "新疆" ||
            item.name == "甘肃" ||
            item.name == "辽宁"
          ) {
            item.itemStyle.normal.areaColor = "#ed5f6a";
          } else if (item.name == "西藏") {
            item.itemStyle.normal.areaColor = "#b73d46";
          } else if (item.name == "青海") {
            item.itemStyle.normal.areaColor = "#ff9aaa";
          } else if (
            item.name == "四川" ||
            item.name == "湖北" ||
            item.name == "北京"
          ) {
            item.itemStyle.normal.areaColor = "#972321";
          } else if (item.name == "云南") {
            item.itemStyle.normal.areaColor = "#963d46";
          } else if (item.name == "内蒙古") {
            item.itemStyle.normal.areaColor = "#bc6171";
          } else if (item.name == "黑龙江") {
            item.itemStyle.normal.areaColor = "#d05e65";
          } else if (item.name == "吉林") {
            item.itemStyle.normal.areaColor = "#c2747a";
          } else if (item.name == "湖南") {
            item.itemStyle.normal.areaColor = "#e57168";
          } else if (item.name == "安徽") {
            item.itemStyle.normal.areaColor = "#921625";
          } else if (item.name == "河北") {
            item.itemStyle.normal.areaColor = "#8a262f";
          } else if (item.name == "山西") {
            item.itemStyle.normal.areaColor = "#c65c62";
          } else if (item.name == "山东") {
            item.itemStyle.normal.areaColor = "#d06c7b";
          } else if (item.name == "江苏") {
            item.itemStyle.normal.areaColor = "#b44953";
          } else {
            item.itemStyle.normal.areaColor = "#d26066";
          }
          this.mapAreaData.push(item);
          this.valueList.push({
            id: i,
            num1: this.mapData[i].small.B_height,
            num2: this.mapData[i].middle.B_height,
            num3: this.mapData[i].big.B_height,
          });
        }
        this.$nextTick(function () {
          this.drawMap();
        });
      });
    },
    // 获取增长率数据
    getAvgData() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.pes.childrenDbRateData(data).then((res) => {
        this.rateData = res.data;
      });
    },
    // 获取一键分析
    getAnalyseData() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.pes.childrenDbAnalyseData(data).then((res) => {
        this.analyseValue = res.data;
        var result = JSON.parse(res.message);
        this.ysName = result.message;
        this.message = result.analy;
        for (let i = 0; i < this.analyseValue.length; i++) {
          this.qgData.push(this.analyseValue[i].B_height[0]);
          this.analyseData.push(this.analyseValue[i].B_height[1]);
          this.qgHeight.push(this.analyseValue[i].B_height_kd[0]);
          this.ysHeight.push(this.analyseValue[i].B_height_kd[1]);
        }
        this.times.push(this.analyseValue[0].time);
        this.times.push(this.analyseValue[1].time);
        this.times.push(this.analyseValue[2].time);
        this.times.push(this.analyseValue[3].time);
        this.times.push(this.analyseValue[4].time);
        this.times.push(this.analyseValue[5].time);
        this.$nextTick(function () {
          this.drawAnalyseLine("analyseChart");
        });
      });
    },
  },
  mounted() {
    this.drawMap();
    this.getMapData();
    this.getAvgData();
    this.getAnalyseData();
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.column {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #e7e7e7;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
}
.topLine {
  width: 863px;
  height: 847px;
  margin-top: -70px;
}
.top_text {
  font-size: 26px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  left: 0;
  position: absolute;
  top: 30px;
  z-index: 1;
}
.row {
  display: flex;
  flex-direction: row;
}
.flex {
  flex: 1;
}
.top_hint {
  display: flex;
  flex-direction: row;
  width: 1700px;
  height: 53px;
  .line {
    width: 2px;
    height: 53px;
    background: #c2c2c2;
  }
  .mrl1 {
    margin-left: 1px;
  }
  .mrl2 {
    margin-left: 2px;
  }
  .mrl3 {
    margin-left: 3px;
  }
  .text {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #00157f;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
}
.mt26 {
  margin-top: 26px;
}
.ml8 {
  margin-left: 8px;
}
.ml32 {
  margin-left: 12px;
}
.ml30 {
  margin-left: 30px;
}
.title {
  font-size: 50px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  margin-top: -70px;
  z-index: 9;
}
.pes {
  width: 150px;
  height: 150px;
  margin-top: 21px;
}
.content {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.mt15 {
  margin-top: 15px;
}
.ml20 {
  margin-left: 12px;
}
.ml55 {
  margin-left: 55px;
}
.pos_rel {
  position: relative;
  width: 960px;
  .label_row {
    position: absolute;
    right: 50px;
    top: 80px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  .column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .row_center {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }
  .all_text {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #606060;
    margin-left: 5px;
  }
  .mrl23 {
    margin-left: 23px;
  }
}
.mt6 {
  margin-top: 6px;
}
.text_normal {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000e56;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text_select {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.width62 {
  width: 72px;
  height: 32px;
  border-radius: 18px;
  cursor: pointer;
}
.color1 {
  background-color: #00b29d;
}
.color2 {
  background-color: #88391e;
}
.color3 {
  background-color: #344f00;
}
.color4 {
  background-color: #002055;
}
.color5 {
  background-color: #005047;
}
.color6 {
  background-color: #500009;
}
.color7 {
  background-color: #41cbb9;
}
.color8 {
  background-color: #a37d8c;
}
.color9 {
  background-color: #55242a;
}
.color10 {
  background-color: #2e7aa2;
}
.color11 {
  background-color: #263d69;
}
.color12 {
  background-color: #705d17;
}
.color13 {
  background-color: #230db5;
}
.color14 {
  background-color: #14c0de;
}
.color15 {
  background-color: #159c56;
}
.width514 {
  width: 514px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .top_lay {
    position: relative;
    width: 100%;
    height: 78px;
  }
  .top_bg {
    width: 498px;
    height: 62px;
    background: #e7e7e7;
    border: 8px solid #ffffff;
    border-radius: 39px;
  }
  .left_text {
    width: 120px;
    height: 62px;
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .middle {
    width: 100%;
    height: 100%;
    font-size: 26px;
    font-family: Microsoft YaHei;
    font-weight: 600;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pos_abs {
    position: absolute;
    left: 0;
    top: 0;
  }
  .marl8 {
    margin-left: 8px;
  }
  .hint1 {
    font-size: 21px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-top: 25px;
  }
  .left_lay {
    width: 170px;
    height: 49px;
    background: #0abda3;
    border: 6px solid #ffffff;
    border-radius: 31px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .text {
      font-size: 38px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
    .text1 {
      font-size: 38px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      margin-left: 4px;
    }
    .text2 {
      width: 5px;
      height: 5px;
      background: #ffffff;
      border-radius: 50%;
      margin-left: 15px;
      margin-top: 30px;
    }
  }
  .num_lay {
    width: 514px;
    height: 61px;
    background: #f2f2f2;
    border-radius: 31px;
    display: flex;
    flex-direction: row;
  }
  .mt16 {
    margin-top: 16px;
  }
  .mt36 {
    margin-top: 36px;
  }
}

.baseLine {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex2 {
  flex: 2;
}
.flex1 {
  flex: 1;
}
.mt72 {
  margin-top: 72px;
}
.width80 {
  width: 80px;
}
.row_center {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.column_num {
  display: flex;
  flex-direction: column;
  margin-left: 6px;
  margin-right: 6px;
  .text {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #888888;
    margin-top: 10px;
  }
  .line {
    width: 130px;
    height: 2px;
    background: #b9b9b9;
  }
  .value {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #0abda3;
    margin-top: 2px;
  }
  .vs {
    width: 39px;
    height: 25px;
    margin-top: 6px;
  }
}
.analyse {
  width: 334px;
  height: 66px;
  background: #00af0c;
  border: 6px solid #00e210;
  border-radius: 39px;
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #010000;
  margin-top: 150px;
  cursor: pointer;
}
.analyse_chart {
  width: 1400px;
  height: 561px;
  margin-top: 63px;
}
.analyse_tab {
  width: 130px;
  height: 33px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .text1 {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #cbcbcb;
  }
  .text2 {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #686868;
  }
}
.width1400 {
  width: 1400px;
}
.ys_name {
  font-size: 26px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #606060;
  margin-top: 20px;
}
.h240 {
  height: 240px;
}
.circle_left {
  border-radius: 0px 0 0px 50px;
  height: 21px;
  width: 21px;
  background-color: #e8e8e8;
}
.circle_right {
  border-radius: 0px 0 50px 0;
  height: 21px;
  width: 21px;
  background-color: #e8e8e8;
}
.mt83 {
  margin-top: 83px;
}
.width1300 {
  width: 1300px;
  .column {
    display: flex;
    flex-direction: column;
    width: 50px;
    .top_circle {
      border-radius: 50px 50px 0 0;
      width: 42px;
      height: 25px;
      border-top: 10px #bdbdbd solid;
      border-left: 10px #bdbdbd solid;
      border-right: 10px #bdbdbd solid;
    }
    .left_pos {
      position: relative;
      width: 10px;
      height: 517px;
    }
    .left_bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 10px;
      height: 517px;
      background: #bdbdbd;
    }
    .left_v {
      width: 10px;
      background: #1b1ec0;
    }
    .abs {
      position: absolute;
      top: 0;
      left: 0;
    }
    .left_value {
      width: 41px;
      height: 14px;
      background: #e8e8e8;
      border: 2px solid #cccccc;
      border-radius: 9px;
      font-size: 14px;
      font-family: AlibabaPuHuiTi_2_85_Bold;
      font-weight: 400;
      color: #001166;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: -20px;
    }
    .left_line_color {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    .mrl42 {
      margin-left: 42px;
    }
    .width42 {
      width: 42px;
      word-wrap: break-word;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .width10 {
        width: 10px;
        color: #00b29d;
        font-size: 16px;
        margin-bottom: 20px;
      }
    }
    .left_text {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border: 2px solid #1b1ec0;
      border-radius: 9px;
      font-size: 12px;
      color: #00b29d;
      margin-left: -10px;
    }
    .right_text {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      font-size: 12px;
      color: #00b29d;
    }
  }
  .mrl210 {
    margin-left: 200px;
  }
}
.h517 {
  height: 517px;
}
.mt82 {
  margin-top: 82px;
}
.column_base {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
.label1 {
  width: 69px;
  height: 23px;
  border: 2px solid #bbbbbb;
  border-radius: 11px;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #606060;
  display: flex;
  align-items: center;
  justify-content: center;
}
.column1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.column2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.width9 {
  width: 9px;
  height: 9px;
  border-width: 7px;
  margin-top: 3px;
}
.mrl5 {
  margin-left: 5px;
}
.mrl16 {
  margin-left: 16px;
}
.ys_color1 {
  background-color: rgba(192, 40, 27, 1);
}
.ys_color2 {
  background-color: rgba(64, 209, 0, 1);
}
.ys_color3 {
  background-color: rgba(0, 168, 193, 1);
}
.ys_color4 {
  background-color: rgba(224, 200, 0, 1);
}
.ys_color5 {
  background-color: rgba(244, 138, 0, 1);
}
.ys_color6 {
  background-color: rgba(39, 0, 237, 1);
}
.ys_color7 {
  background-color: rgba(214, 0, 253, 1);
}
.ys_color8 {
  background-color: rgba(151, 32, 79, 1);
}
.ys_color9 {
  background-color: rgba(150, 36, 49, 1);
}
.ys_color10 {
  background-color: rgba(46, 162, 122, 1);
}
.ys_color11 {
  background-color: rgba(31, 68, 135, 1);
}
.ys_color12 {
  background-color: rgba(182, 150, 35, 1);
}
.ys_color13 {
  background-color: rgba(10, 0, 81, 1);
}
.ys_color14 {
  background-color: rgba(24, 130, 148, 1);
}
.ys_color15 {
  background-color: rgba(15, 193, 100, 1);
}
.ys_text {
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  margin-top: 10px;
  color: #606060;
}
.mrl9 {
  margin-left: 9px;
}
.alpha {
  background: #e7e7e7;
}
.bottom_select {
  width: 36px;
  height: 66px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background: #dddddd;
}
.bottom_normal {
  width: 36px;
  height: 66px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background: #e7e7e7;
}
.empty_text {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #da3c2d;
}
.result {
  width: 500px;
  margin-top: 26px;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.column_center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.red_text {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ce2323;
  margin-top: 20px;
}
</style>